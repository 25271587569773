<template>
  <v-container>
    <!-- プラン選択 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title1')"
    />
    <v-col class="pt-0">
      <span>{{ $t('description.reviewContract.explanation1') }}</span>
    </v-col>
    <v-container class="pt-0" v-if="!finalReviewFlag">
      <v-col>
        <v-row
          v-for="plan in planChoices"
          :key="plan.key"
          style="align-items: center;"
          class="p-0"
        >
          <v-col cols="5">
            <label :for="plan.key">{{ plan.planName }}</label>
          </v-col>
          <v-col cols="1">
            <div v-if="plan.comment">
              <div
                @click="openPlanExplanationDialog(plan)"
                class="plan-explanation ml-2"
              ></div>
            </div>
          </v-col>
          <v-col cols="3">
            <!-- プランが選択された場合、ボタンを水色にする -->
            <v-btn
              class="ml-2"
              align="center"
              width="15px"
              max-width="30px"
              height="25px"
              style="font-size:10px"
              :color="plan.key === selectedTab ? 'next': 'disabled'"
              dark
              @click="targetTabData(plan.key)"
            >
              {{ $t('button.display') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-container>

    <!-- プラン説明モーダル -->
    <ItemExplanationDialog
      :ref="`planExplanationDialog${plan.id}`"
      :maxWidth="320"
      :showDialog="showDialog"
      :title="plan.planName"
      :text="plan.comment"
      :negativeButtonTitle="$t('button.close')"
      :onClickNegativeButton="closePlanExplanationDialog"
    />

    <!-- 基本情報 -->
    <!-- 表全体のヘッダー(前年同等条件プラン・おすすめ1・おすすめ2)はこちらで制御 -->
    <PlanTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title2')"
      :content-page-name="contentPageName"
      :records="basicInformationRecords"
    />

    <!-- 保険金額 -->
    <PlanTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title3')"
      :content-page-name="contentPageName"
      :records="insuranceAmountRecords"
    />

    <!-- 損害保険金 -->
    <PlanCoverageTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title4')"
      :content-page-name="contentPageName"
      :plan="displayPlan"
      :records="damageInsuranceAmountRecords"
    />

    <!-- 地震保険 -->
    <PlanTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title5')"
      :content-page-name="contentPageName"
      :records="earthquakeInsuranceRecords"
    />

    <!-- 費用保険金 -->
    <PlanTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title6')"
      :content-page-name="contentPageName"
      :records="costBurdenAmountRecords"
    />

    <!-- 特約 -->
    <CollateralPlanOptionTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title7')"
      :content-page-name="contentPageName"
      :records="collateralPlanOptionRecords"
    />

    <!-- 自動セットされる特約 -->
    <PlanTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title8')"
      :content-page-name="contentPageName"
      :records="autoSetPlanOptionRecords"
    />

    <!-- その他の特約 -->
    <PlanTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title9')"
      :content-page-name="contentPageName"
      :records="anotherPlanOptionRecords"
    />

    <!-- 割増引 -->
    <PlanTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title10')"
      :content-page-name="contentPageName"
      :records="discountRateRecords"
    />

    <!-- 払込方法の確認 -->
    <PlanTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title11')"
      :content-page-name="contentPageName"
      :records="paymentMethodRecords"
    />

    <!-- 合計保険料 -->
    <PlanTable
      :title="$t('tableItems.reviewContract.stepThirdContent.title12')"
      :content-page-name="contentPageName"
      :records="totalPremiumRecords"
    />

    <ScrollToTopButton />
  </v-container>
</template>
<script>
import moment from 'moment';
import ReviewContractTable from '@/pages/contractor/reviewContractTemplates/ReviewContractTable.vue';
import PlanTable from '@/pages/contractor/reviewContractTemplates/PlanTable.vue';
import PlanCoverageTable from '@/pages/contractor/reviewContractTemplates/PlanCoverageTable.vue';
import CollateralPlanOptionTable from '@/pages/contractor/reviewContractTemplates/CollateralPlanOptionTable.vue';
import ItemExplanationDialog from '@/components/organisms/contractor/ItemExplanationDialog.vue';
import ScrollToTopButton from '@/components/organisms/common/ScrollToTopButton.vue';
import {
  basicInformationData,
  insuranceAmountData,
  damageInsuranceAmountData,
  earthquakeInsuranceData,
  costBurdenAmountData,
  collateralPlanOptionData,
  autoSetPlanOptionData,
  anotherPlanOptionData,
  discountRateData,
  paymentMethodData,
} from '@/lib/reviewContractData/stepThirdContentData';
import { paymentGroupMethodCode } from '@/lib/const';
import {
  getPlanPayout,
  getPlanCoverages,
  getCollateralPlanOptions,
  getPlanOptions,
} from '@/apis/contractor/plans';
import { getOffice } from '@/apis/contractor/offices';
import { convertProductNameText } from "@/lib/commonUtil";
import { mapGetters } from "vuex";
import { getFormItems } from "@/apis/contractor/formItems";
export default {
  components: {
    ReviewContractTable,
    PlanTable,
    PlanCoverageTable,
    CollateralPlanOptionTable,
    ItemExplanationDialog,
    ScrollToTopButton,
  },
  props: {
    contract: {
      type: Object,
      required: true
    },
    planChoices: Array,
    finalReviewFlag: Boolean,
  },
  data: () => ({
    contentPageName: 'stepThirdContent',
    tabList: ['planTab1', 'planTab2', 'planTab3'],
    selectedTab: 'planTab1',
    basicInformationAllTabRecords: {},
    insuranceAmountAllTabRecords: {},
    damageInsuranceAmountAllTabRecords: {},
    earthquakeInsuranceAllTabRecords: {},
    costBurdenAmountAllTabRecords: {},
    collateralPlanOptionAllTabRecords: {},
    autoSetPlanOptionAllTabRecords: {},
    anotherPlanOptionAllTabRecords: {},
    discountRateAllTabRecords: {},
    paymentMethodAllTabRecords: {},
    totalPremiumAllTabRecords: {},
    basicInformationRecords: {},
    insuranceAmountRecords: {},
    damageInsuranceAmountRecords: [],
    earthquakeInsuranceRecords: {},
    costBurdenAmountRecords: {},
    collateralPlanOptionRecords: [],
    autoSetPlanOptionRecords: {},
    anotherPlanOptionRecords: {},
    discountRateRecords: {},
    paymentMethodRecords: {},
    totalPremiumRecords: {},
    displayPlan: {},
    showDialog: false,
    plan: [],
  }),

  async mounted() {
    this.tabList.forEach(tab => {
      this.basicInformationAllTabRecords[tab] = JSON.parse(
        JSON.stringify(basicInformationData)
      );
      this.insuranceAmountAllTabRecords[tab] = JSON.parse(
        JSON.stringify(insuranceAmountData)
      );
      this.damageInsuranceAmountAllTabRecords[tab] = JSON.parse(
        JSON.stringify(damageInsuranceAmountData)
      );
      this.earthquakeInsuranceAllTabRecords[tab] = JSON.parse(
        JSON.stringify(earthquakeInsuranceData)
      );
      this.costBurdenAmountAllTabRecords[tab] = JSON.parse(
        JSON.stringify(costBurdenAmountData)
      );
      this.collateralPlanOptionAllTabRecords[tab] = JSON.parse(
        JSON.stringify(collateralPlanOptionData)
      );
      this.autoSetPlanOptionAllTabRecords[tab] = JSON.parse(
        JSON.stringify(autoSetPlanOptionData)
      );
      this.anotherPlanOptionAllTabRecords[tab] = JSON.parse(
        JSON.stringify(anotherPlanOptionData)
      );
      this.discountRateAllTabRecords[tab] = JSON.parse(
        JSON.stringify(discountRateData)
      );
      this.paymentMethodAllTabRecords[tab] = JSON.parse(
        JSON.stringify(paymentMethodData)
      );
      this.totalPremiumAllTabRecords[tab] = {};
    });
  },

  watch: {
    async planChoices() {
      await this.init();
      this.setDisplayPlan();
      this.targetTabData(this.selectedTab);
    },

    selectedTab() {
      this.setDisplayPlan();
    }
  },

  methods: {
    async init() {
      // formItemを取得
      const planPremiumsformItems = await getFormItems('planPremium').catch(() => {});
      // 合計保険料
      const totalPremiumFormItem = planPremiumsformItems.find(formItem => {
        return formItem.planPremiums.every(planPremium => planPremium.formItemModels.columnName === 'totalPremium')
      })
      // 地震保険料
      const earthquakePricePremiumFormItem = planPremiumsformItems.find(formItem => {
        return formItem.planPremiums.every(planPremium => planPremium.formItemModels.columnName === 'earthquakePrice')
      })

      for (let i = 0; i < this.planChoices.length; i++) {
        // プランTab番号取得
        const planTabNumber = 'planTab' + this.planChoices[i].number;
        // 〇基本情報
        const basicInformationTabRecord = this.basicInformationAllTabRecords[planTabNumber];
        // 保険種類
        this.$set(
          basicInformationTabRecord.productName,
          'value',
          this.getConvertProductNameText(this.contract.productNameText)
        );

        const insurancePeriodText = this.planChoices[i].insurancePeriodText ? ` ${this.planChoices[i].insurancePeriodText}` : ''
        let insurancePeriodTextValue;
        if (this.planChoices[i].startDate && this.planChoices[i].endDate) { // startDateとendDateが存在する場合
          insurancePeriodTextValue = `${moment(this.planChoices[i].startDate).format('YYYY年M月D日')}\n${moment(this.planChoices[i].endDate).format('YYYY年M月D日')}${insurancePeriodText}`;
        } else if (this.planChoices[i].insurancePeriodText) { // startDateとendDateがnullで、insurancePeriodTextのみ存在する場合
          insurancePeriodTextValue = this.$t('header.planTable.startDateEndDateText') + `${insurancePeriodText}`;
        }

        // 保険期間(基本)
        this.$set(
          basicInformationTabRecord.insurancePeriodText,
          'value',
          insurancePeriodTextValue
        );
        // 保険責任開始時刻・ 終了時刻
        this.$set(
          basicInformationTabRecord.insurancePeriodTimeText,
          'value',
          this.contract.insurancePeriodTimeText
        );
        // 地震保険
        this.$set(
          basicInformationTabRecord.earthquakeInsurancePeriodText,
          'value',
          this.planChoices[i].earthquakeInsurancePeriodText
        );

        // 保険金額情報を取得
        const planPayout = await getPlanPayout(
          this.planChoices[i].id
        ).catch(() => {});

        // 〇保険金額
        const insuranceAmountTabRecord = this.insuranceAmountAllTabRecords[planTabNumber];
        // 建物_基本保険金額
        this.$set(
          insuranceAmountTabRecord.buildingBasicPrice,
          'value',
          planPayout.buildingBasicPrice
        );
        // 建物_上乗せ
        this.$set(
          insuranceAmountTabRecord.addOnText,
          'value',
          this.planChoices[i].addOnText
        );
        // 建物_地震保険金額
        this.$set(
          insuranceAmountTabRecord.buildingEarthquakePrice,
          'value',
          planPayout.buildingEarthquakePrice
        );
        // 家財_基本保険金額
        this.$set(
          insuranceAmountTabRecord.houseItemBasicPrice,
          'value',
          planPayout.houseItemBasicPrice
        );
        // 家財_地震保険金額
        this.$set(
          insuranceAmountTabRecord.houseItemEarthquakePrice,
          'value',
          planPayout.houseItemEarthquakePrice
        );
        // 家財_貴金属等以外基本保険金額
        this.$set(
          insuranceAmountTabRecord.houseItemNonPreciousMetalBasicPrice,
          'value',
          planPayout.houseItemNonPreciousMetalBasicPrice
        );
        // 家財_貴金属等基本保険金額
        this.$set(
          insuranceAmountTabRecord.houseItemPreciousMetalBasicPrice,
          'value',
          planPayout.houseItemPreciousMetalBasicPrice
        );

        // 保証情報を取得
        const planCoverages = await getPlanCoverages(
          this.planChoices[i].id
        ).catch(() => {});

        // 〇損害保険金
        for (let j = 0; j < planCoverages.length; j++) {
          this.damageInsuranceAmountAllTabRecords[planTabNumber].push({
            key: planCoverages[j].name,
            value1: this.getBooleanText(planCoverages[j].isEnabled),
            value2: planCoverages[j].copaymentPrice,
          });
        }

        // 〇地震保険
        const earthquakeInsuranceTabRecord = this.earthquakeInsuranceAllTabRecords[planTabNumber];
        // 建物
        this.$set(
          earthquakeInsuranceTabRecord.isBuildingEnabled,
          'value',
          this.getBooleanText(this.planChoices[i].isBuildingEnabled)
        );

        // 家財
        this.$set(
          earthquakeInsuranceTabRecord.isHouseItemEnabled,
          'value',
          this.getBooleanText(this.planChoices[i].isHouseItemEnabled)
        );

        // 〇費用保険金
        const costBurdenAmountTabRecord = this.costBurdenAmountAllTabRecords[planTabNumber];
        // 地震火災費用
        this.$set(
          costBurdenAmountTabRecord.earthquakeFirePrice,
          'value',
          planPayout.earthquakeFirePrice
        );
        // 凍結水道管修理費用
        this.$set(
          costBurdenAmountTabRecord.waterPipeRepairPrice,
          'value',
          planPayout.waterPipeRepairPrice
        );
        // 臨時費用
        this.$set(
          costBurdenAmountTabRecord.contingencyPrice,
          'value',
          planPayout.contingencyPrice
        );

        // 担保特約情報を取得
        const collateralPlanOptions = await getCollateralPlanOptions(
          this.planChoices[i].id
        ).catch(() => {});

        // 〇特約
        const enabledCollateralPlanOptions = collateralPlanOptions.filter(collateralPlanOption => collateralPlanOption.name !== null);
        const collateralPlanOptionTabRecord = this.collateralPlanOptionAllTabRecords[planTabNumber];

        if (enabledCollateralPlanOptions.length >= 1) {
          for (let j = 0; j < enabledCollateralPlanOptions.length; j++) {
            collateralPlanOptionTabRecord.push({
              key: enabledCollateralPlanOptions[j].name,
              value1: enabledCollateralPlanOptions[j].insurancePrice,
              value2: enabledCollateralPlanOptions[j].copaymentPrice,
            });
          }
        } else {
          collateralPlanOptionTabRecord.push({
            key: '　',
            value1: '　',
            value2: '　',
          });
        }

        // 特約情報を取得
        const planOptions = await getPlanOptions(
          this.planChoices[i].id
        ).catch(() => {});

        // 〇自動セットされる特約
        // 自動セットされる特約
        this.$set(
          this.autoSetPlanOptionAllTabRecords[planTabNumber].autoSetPlanOption,
          'value',
          planOptions[0].name
        );

        // 〇その他の特約
        // その他の特約
        this.$set(
          this.anotherPlanOptionAllTabRecords[planTabNumber].anotherPlanOption,
          'value',
          planOptions[1].name
        );

        // 保険料情報を取得
        const planPremium = this.planChoices[i].planPremium;

        // 〇割増引
        // 割増引
        this.$set(
          this.discountRateAllTabRecords[planTabNumber].discountRate,
          'value',
          planPremium.discountRate
        );

        // 〇払込方法の確認
        const paymentMethodTabRecord = this.paymentMethodAllTabRecords[planTabNumber];
        // 払込方法
        this.$set(
          paymentMethodTabRecord.paymentTypeName,
          'value',
          this.planChoices[i].payment.paymentTypeName
        );
        // 支払方法
        if(this.planChoices[i].payment.transferTypeName) {
          this.$set(
            paymentMethodTabRecord.transferTypeName,
            'value',
            this.planChoices[i].payment.transferTypeName
          );
        } else {
          // transferTypeNameがnullだった場合、行ごと削除
          delete paymentMethodTabRecord.transferTypeName;
        }

        const office = await getOffice().catch(() => {});
        const handlingTypeOther = office.paymentGroupMethodCode === paymentGroupMethodCode['その他'];
        
        // 登録内容
        // (債務者集団扱・団体扱/集団扱の場合)
        if (!handlingTypeOther && this.planChoices[i].payment.paymentAgencyFinancialAccount) {
          this.$set(
            paymentMethodTabRecord.financialAccount,
            'value',
            this.planChoices[i].payment.paymentAgencyFinancialAccount
          );
        } else { 
          // (債務者集団扱・団体扱/集団扱以外の場合)
          this.setHandlingTypeOtherPaymentData(
            handlingTypeOther,
            paymentMethodTabRecord,
            'financialAccount',
            this.planChoices[i].payment.financialAccount
          );
        }
        // 新規登録以降は、債務者集団扱・団体扱/集団扱以外の場合表示
        // 新規登録
        this.setHandlingTypeOtherPaymentData(
          handlingTypeOther,
          paymentMethodTabRecord,
          'newRegistration',
          this.planChoices[i].payment.newRegistration
        );
        // 作成方法
        this.setHandlingTypeOtherPaymentData(
          handlingTypeOther,
          paymentMethodTabRecord,
          'createMethod',
          this.planChoices[i].payment.createMethod
        );
        // お知らせ方法
        this.setHandlingTypeOtherPaymentData(
          handlingTypeOther,
          paymentMethodTabRecord,
          'notificationMethod',
          this.planChoices[i].payment.notificationMethod
        );
        // お払込み先コンビニ　
        this.setHandlingTypeOtherPaymentData(
          handlingTypeOther,
          paymentMethodTabRecord,
          'paymentConvenienceStore',
          this.planChoices[i].payment.paymentConvenienceStore
        );

        // 〇合計保険料
        const totalPremiumTabRecord = this.totalPremiumAllTabRecords[planTabNumber];
        // 合計保険料
        if (totalPremiumFormItem) {
          this.$set(
            totalPremiumTabRecord,
            totalPremiumFormItem.title,
            { value: planPremium[totalPremiumFormItem.planPremiums.find(formItemPlanPremium => formItemPlanPremium.id === planPremium.id).formItemModels.columnName] }
          );
        }
        // 基本保険料
        if (planPremium.basicPrice) {
          this.$set(
            totalPremiumTabRecord,
            this.modifiedPriceName(this.$t('header.planTable.basicPrice')),
            { value: planPremium.basicPrice }
          );
        }
        // 地震保険料
        if (earthquakePricePremiumFormItem && planPremium.earthquakePrice) {
          this.$set(
            totalPremiumTabRecord,
            this.modifiedPriceName(earthquakePricePremiumFormItem.title),
            { value: planPremium[earthquakePricePremiumFormItem.planPremiums.find(formItemPlanPremium => formItemPlanPremium.id === planPremium.id).formItemModels.columnName] }
          );
        }
        // 総合計保険料
        if (planPremium.grandTotalPrice) {
          this.$set(
            totalPremiumTabRecord,
            'grandTotalPrice',
            { value: planPremium.grandTotalPrice}
          );
        }
      }
    },

    // 債務者集団扱・団体扱/集団扱以外の場合にセット
    // nullは行ごと非表示、空文字は空欄で表示
    setHandlingTypeOtherPaymentData(handlingType, record, key, value) {
      if (handlingType && value !== null) {
        this.$set(record, key, { value });
      } else {
        delete record[key];
      }
    },

    modifiedPriceName(priceTitle) {
      return "　" + priceTitle; // 先頭に全角1文字分のスペースを追加
    },

    setDisplayPlan() {
      const mainPath = (this.$route.path).split('#')[0];
      if (mainPath === '/check_need_upload') {
        this.selectedTab = this.planChoices[0].key;
      }
      const plan = this.planChoices.find(plan => plan.key === this.selectedTab);

      if (!plan) throw new Error('not found plan');

      this.displayPlan = plan;
    },

    targetTabData(tabName) {
      this.selectedTab = tabName;

      this.basicInformationRecords = this.basicInformationAllTabRecords[
        tabName
      ];

      this.insuranceAmountRecords = this.insuranceAmountAllTabRecords[tabName];
      this.damageInsuranceAmountRecords = this.damageInsuranceAmountAllTabRecords[
        tabName
      ];
      this.earthquakeInsuranceRecords = this.earthquakeInsuranceAllTabRecords[
        tabName
      ];
      this.costBurdenAmountRecords = this.costBurdenAmountAllTabRecords[
        tabName
      ];
      this.collateralPlanOptionRecords = this.collateralPlanOptionAllTabRecords[
        tabName
      ];
      this.autoSetPlanOptionRecords = this.autoSetPlanOptionAllTabRecords[
        tabName
      ];
      this.anotherPlanOptionRecords = this.anotherPlanOptionAllTabRecords[
        tabName
      ];
      this.discountRateRecords = this.discountRateAllTabRecords[tabName];
      this.paymentMethodRecords = this.paymentMethodAllTabRecords[tabName];
      this.totalPremiumRecords = this.totalPremiumAllTabRecords[tabName];
    },
    openPlanExplanationDialog(clickedPlan) {
      this.showDialog = true;
      this.plan = clickedPlan;
    },
    closePlanExplanationDialog() {
      this.showDialog = false;
    },
    getBooleanText(booleanValue) {
      if (booleanValue === null) return null

      // [false:×][true:○]のパターンがある。
      return booleanValue
        ? this.$t('header.planTable.true')
        : this.$t('header.planTable.false');
    },

    getConvertProductNameText(productNameText) {
      return convertProductNameText(productNameText)
    }
  },
};
</script>
<style>
.plan-explanation {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  border: solid 1px black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.plan-explanation::before {
  content: '?';
  font-size: 13px;
  color: #0069d8;
  font-weight: bold;
  position: absolute;
}

.dotted-line {
  border-top: 2px dotted #cccccc;
  margin-bottom: 10px;
}

.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
</style>
