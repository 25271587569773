var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      class: _vm.isInsuredTitleRecords
        ? _vm.noPaddingStyle
        : _vm.standardPaddingStyle,
    },
    [
      _c(
        "v-row",
        [
          _vm.title !== null
            ? _c(
                "v-col",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "pa-0",
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "nowrap",
                        "align-items": "baseline",
                      },
                    },
                    [
                      _vm.title
                        ? _c("v-img", {
                            attrs: {
                              src: require("@/assets/img/title-item.png"),
                              "max-width": "10px",
                              "max-height": "20px",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold ml-2",
                          staticStyle: { "font-size": "large" },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.title) + "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("span", { attrs: { if: "explanation" } }, [
                    _vm._v("\n        " + _vm._s(_vm.explanation) + "\n      "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c("v-container", { staticClass: "ma-0 pa-0" }, [
            _c(
              "table",
              {
                staticClass: "ma-0",
                staticStyle: {
                  width: "100%",
                  "border-spacing": "5px",
                  "font-size": "14px",
                },
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.records, function (record) {
                      return [
                        _c("tr", [
                          _vm.isExplanationItem(record.key)
                            ? _c(
                                "td",
                                {
                                  staticClass:
                                    "text-left base_td_category_text px-2",
                                  staticStyle: {
                                    "text-decoration": "underline",
                                    cursor: "pointer",
                                  },
                                  attrs: {
                                    colspan: _vm.getColspan(record.value),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleShowItemExplanation(
                                        record.key
                                      )
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.splitMessageLine(
                                    _vm.$t(
                                      `header.reviewContractTable.${_vm.contentPageName}.${record.key}`
                                    )
                                  ),
                                  function (message, index) {
                                    return _c(
                                      "p",
                                      {
                                        key: index,
                                        staticClass: "ma-0",
                                        staticStyle: {
                                          "word-wrap": "break-word",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(message) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c(
                                "td",
                                {
                                  staticClass:
                                    "text-left base_td_category_text px-2 py-2",
                                  attrs: {
                                    colspan: _vm.getColspan(record.value),
                                  },
                                },
                                [
                                  _vm.$te(
                                    `header.reviewContractTable.${_vm.contentPageName}.${record.key}`
                                  )
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.splitMessageLine(
                                            _vm.$t(
                                              `header.reviewContractTable.${_vm.contentPageName}.${record.key}`
                                            )
                                          ),
                                          function (message, index) {
                                            return _c(
                                              "p",
                                              {
                                                key: index,
                                                staticClass: "ma-0",
                                                staticStyle: {
                                                  "word-wrap": "break-word",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(message) +
                                                    "\n                  "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c(
                                        "div",
                                        _vm._l(
                                          _vm.splitMessageLine(record.key),
                                          function (message, index) {
                                            return _c(
                                              "p",
                                              {
                                                key: index,
                                                staticClass: "ma-0",
                                                staticStyle: {
                                                  "word-wrap": "break-word",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(message) +
                                                    "\n                  "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                ]
                              ),
                          !record.titleLineFlg
                            ? _c(
                                "td",
                                {
                                  staticClass: "pl-2 py-2",
                                  staticStyle: { border: "solid 1px #cccccc" },
                                },
                                _vm._l(
                                  _vm.splitMessageLine(record.value),
                                  function (message, index) {
                                    return _c(
                                      "p",
                                      {
                                        key: index,
                                        staticClass: "ma-0",
                                        staticStyle: {
                                          "word-wrap": "break-word",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(message) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                        _vm.isInsuredTitleRecords
                          ? _c("tr", { staticClass: "ma-0 pa-0" }, [
                              _c(
                                "td",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { colspan: 2 },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-left ma-0 pa-0 font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.noticeText) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("ItemExplanationDialog", {
        attrs: {
          maxWidth: 320,
          showDialog: _vm.showItemExplanation,
          title: _vm.explanationItemTitle,
          text: _vm.explanationItemText,
          negativeButtonTitle: _vm.$t("button.close"),
          onClickNegativeButton: _vm.toggleShowItemExplanation,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }