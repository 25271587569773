<template>
  <v-container>
    <!-- 保険始期のご確認 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepFirstContent.title1')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepFirstContent.explanation')
      "
      :records="startDateRecords"
    />

    <!-- 申込人氏名・住所のご確認 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepFirstContent.title2')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepFirstContent.explanation')
      "
      :records="baseRecords"
    />

    <!-- 保険の対象の被保険者（所有者）氏名・家族構成のご確認 -->
    <ReviewContractTable
      :title="$t('tableItems.reviewContract.stepFirstContent.title3')"
      :content-page-name="contentPageName"
      :explanation="
        $t('tableItems.reviewContract.stepFirstContent.explanation')
      "
      :records="insuredRecords"
    />

    <!-- 記名被保険者 -->
    <ReviewContractTable
      :title=null
      :content-page-name="contentPageName"
      :explanation=null
      :noticeText="
        $t('tableItems.reviewContract.stepFirstContent.noticeText')
      "
      :records="insuredTitleRecords"
      :isInsuredTitleRecords="true"
    />

  </v-container>
</template>
<script>
import ReviewContractTable from '@/pages/contractor/reviewContractTemplates/ReviewContractTable.vue';
import { convertEraDate, postalCodeAndFullAddress } from '@/lib/commonUtil';
import {
  startDateData,
  baseData,
  insuredData,
} from '@/lib/reviewContractData/stepFirstContentData';
import {
  getContract,
  getContractUserCompanies,
} from '@/apis/contractor/contracts';
import { getFormItems } from '@/apis/contractor/formItems';
export default {
  components: {
    ReviewContractTable,
  },
  props: {
    contract: {
     type: Object,
     required: true
    }
  },
  data: () => ({
    contentPageName: 'stepFirstContent',
    startDateRecords: startDateData,
    baseRecords: baseData,
    insuredRecords: insuredData,
    insuredTitleRecords: [],
  }),

  async mounted() {
    // 保険契約者勤務先情報を取得
    const contractUserCompanies = await getContractUserCompanies().catch(
      () => {}
    );
    // 可変項目情報(記名被保険者情報)を取得
    const formItems = await getFormItems('insuredRegistration').catch(() => {});

    // 保険始期のご確認
    this.setStartDateRecords();

    // 申込人氏名・住所のご確認
    this.setBaseRecords(contractUserCompanies);

    // 保険の対象の被保険者（所有者）氏名・家族構成のご確認
    this.setInsuredRecords(formItems);
  },

  methods: {
    setStartDateRecords: function() {
      // 保険始期のご確認
      this.startDateRecords.forEach((object, index) => {
        // 保険始期
        this.setStepFirstContentData(
          object.key,
          'startDate',
          convertEraDate(this.contract.startDate),
          this.startDateRecords,
          index
        );
        // 計算基準日
        this.setStepFirstContentData(
          object.key,
          'calculationBaseDate',
          convertEraDate(this.contract.calculationBaseDate),
          this.startDateRecords,
          index
          );
      });
      if (this.contract.startDate || (!this.contract.startDate && !this.contract.calculationBaseDate)) { // 保険始期がある場合、または、保険始期と計算基準日が両方ない場合、計算基準日を削除
        this.startDateRecords = this.startDateRecords.filter(
          (object) => object.key !== 'calculationBaseDate'
        );
      } else { // 保険始期がなく、計算基準日がある場合、保険始期を削除
        this.startDateRecords = this.startDateRecords.filter(
          (object) => object.key !== 'startDate'
        );
      }
    },

    setBaseRecords: function(contractUserCompanies) {
      // 申込人氏名・住所のご確認
      this.baseRecords.forEach((object, index) => {
        // 申込人氏名(漢字)
        this.setStepFirstContentData(
          object.key,
          'fullName',
          this.contract.contractUser.fullName,
          this.baseRecords,
          index
        );

        // 申込人氏名(カナ)
        this.setStepFirstContentData(
          object.key,
          'fullNameKana',
          this.contract.contractUser.fullNameKana,
          this.baseRecords,
          index
        );

        // 申込人住所(漢字)
        this.setStepFirstContentData(
          object.key,
          'fullAddress',
          this.postalCodeAndFullAddress(this.contract.contractUser.postalCode, this.contract.contractUser.fullAddress),
          this.baseRecords,
          index
        );

        // 個人・法人
        this.setStepFirstContentData(
          object.key,
          'personType',
          this.contract.personType,
          this.baseRecords,
          index
        );

        // 生年月日
        this.setStepFirstContentData(
          object.key,
          'birthday',
          convertEraDate(this.contract.contractUser.birthday),
          this.baseRecords,
          index
        );

        // 性別
        this.setStepFirstContentData(
          object.key,
          'sex',
          this.contract.contractUser.sex,
          this.baseRecords,
          index
        );

        // 電話番号１
        this.setStepFirstContentData(
          object.key,
          'firstPhoneNumber',
          this.getFullPhoneNumber(this.contract.contractUser.firstPhoneNumberType, this.contract.contractUser.firstPhoneNumber),
          this.baseRecords,
          index
        );

        // 電話番号２
        this.setStepFirstContentData(
            object.key,
            'secondPhoneNumber',
            this.getFullPhoneNumber(this.contract.contractUser.secondPhoneNumberType, this.contract.contractUser.secondPhoneNumber),
            this.baseRecords,
            index
        );

        // 勤務先電話番号
        this.setStepFirstContentData(
          object.key,
          'phoneNumber',
          contractUserCompanies.phoneNumber,
          this.baseRecords,
          index
        );

        // 勤務先内線番号
        this.setStepFirstContentData(
          object.key,
          'extension',
          contractUserCompanies.extension,
          this.baseRecords,
          index
        );

        // 所属名
        this.setStepFirstContentData(
          object.key,
          'name',
          contractUserCompanies.name,
          this.baseRecords,
          index
        );

        // 所属コード
        this.setStepFirstContentData(
          object.key,
          'code',
          contractUserCompanies.code,
          this.baseRecords,
          index
        );

        // 職員番号
        this.setStepFirstContentData(
          object.key,
          'employeeNumber',
          this.contract.contractUser.employeeNumber,
          this.baseRecords,
          index
        );
      });
    },

    postalCodeAndFullAddress(postalCode, fullAddress) {
      return postalCodeAndFullAddress(postalCode, fullAddress)
    },

    setInsuredRecords: function(formItems) {
      // 保険の対象の被保険者（所有者）氏名・家族構成のご確認
      this.insuredRecords.forEach((object, index) => {
        // 被保険者氏名（漢字）
        this.setStepFirstContentData(
          object.key,
          'insuredFullName',
          this.contract.insuredUser.fullName,
          this.insuredRecords,
          index
        );

        // 被保険者氏名（カナ）
        this.setStepFirstContentData(
          object.key,
          'insuredFullNameKana',
          this.contract.insuredUser.fullNameKana,
          this.insuredRecords,
          index
        );

        // 電話番号１
        this.setStepFirstContentData(
            object.key,
            'firstPhoneNumber',
            this.getFullPhoneNumber(this.contract.insuredUser.firstPhoneNumberType, this.contract.insuredUser.firstPhoneNumber),
            this.insuredRecords,
            index
        );

        // 電話番号２
        this.setStepFirstContentData(
            object.key,
            'secondPhoneNumber',
            this.getFullPhoneNumber(this.contract.insuredUser.secondPhoneNumberType, this.contract.insuredUser.secondPhoneNumber),
            this.insuredRecords,
            index
        );

        // 世帯主年齢
        this.setStepFirstContentData(
          object.key,
          'insuredAge',
          this.contract.insuredUser.age,
          this.insuredRecords,
          index
        );

        // 家族構成
        this.setStepFirstContentData(
          object.key,
          'familyStructure',
          this.getFamilyStructure(
            this.contract.insuredUser.adultFamilySize,
            this.contract.insuredUser.childFamilySize
          ),
          this.insuredRecords,
          index
        );
      });

      // 記名被保険者
      for (let i = 0; i < formItems.length; i++) {
        const attribute = formItems[i].insuredRegistrations[0].formItemModels.columnName
        const attributeValue = formItems[i].insuredRegistrations[0][attribute]

        // INSERT時、formItems.titleは必ず値が入るようにしているがNULLチェックを一応行う。
        if (formItems[i].title === null || attributeValue === null) continue;

        this.insuredTitleRecords.push({
          key:
            formItems[i].title +
            this.$t(`header.reviewContractTable.stepFirstContent.insuredText`),
          value: attributeValue,
        });
      }
    },

    setStepFirstContentData: function(
      key,
      keyName,
      data,
      dataObject,
      index
    ) {
      if (key === keyName) {
          this.$set(dataObject[index], 'value', data);
      }
    },

    getFamilyStructure: function(adultFamilySize, childFamilySize) {
      let returnValue = null;
      if (adultFamilySize !== null) {
        returnValue =
          this.$t('header.reviewContractTable.stepFirstContent.adult') +
          adultFamilySize;
      }
      if (childFamilySize !== null) {
        if (returnValue !== null) {
          returnValue = returnValue + '、';
        }
        returnValue =
          returnValue +
          this.$t('header.reviewContractTable.stepFirstContent.child') +
          childFamilySize;
      }
      return returnValue;
    },

    getFullPhoneNumber: function(phoneNumberType, phoneNumber) {
      if (phoneNumberType && phoneNumber) return `${phoneNumberType} ${phoneNumber}`;

      return null;
    }
  },
};
</script>
<style>
.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
</style>
